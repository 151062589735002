<template>
    <v-container :fluid="true">
        <v-row>
            <v-col sm="12" offset="0" lg="8" offset-lg="2" >            
                <v-row>
                    <v-col    v-if="help_onboarding.normal_completeprofile"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-alert
                                class="text-center"
                                colored-border
                                :color="'red'"
                            >
                                <div class="card-title">
                                    1st Step: Complete Your Profile!
                                </div>
                                <!-- <p class="text--disabled">11/05/2020</p> -->
                                <p></p>
                                <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/profileedit"                         
                                >                                    
                                    Click here to complete your Profile
                                    <!--<v-icon right>mdi-emoticon-happy-outline</v-icon>-->
                                </v-btn> 
                                <div class="d-flex justify-space-between">
                                    <div>
                                        <!-- <v-btn icon color="warning">
                                            <v-icon
                                                >mdi-star-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="danger"
                                            @click="deleteNote(note)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn> -->
                                    </div>
                                    <!-- <div>
                                        <v-btn icon color="success">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>
                                    </div> -->
                                </div>
                            </v-alert>
                        </base-card>
                    </v-col>






                    <v-col    v-if="help_onboarding.normal_bookyourfirstsession"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-alert
                                class="text-center"
                                colored-border
                                :color="'red'"
                            >
                                <div class="card-title">
                                    2nd Step: Book your first session!
                                </div>
                                <!-- <p class="text--disabled">11/05/2020</p> -->
                                <p></p>
                                <!-- <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/profileedit"                         
                                >                                    
                                    Click here to complete your Profile                                    
                                </v-btn>  -->
                                <div class="d-flex- justify-space-between text-center">
                                    <div>
                                        Click on the first card "Arrange a new session" to search for available therapists and arrange your first session!
                                        <!-- <v-btn icon color="warning">
                                            <v-icon
                                                >mdi-star-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="danger"
                                            @click="deleteNote(note)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn> -->
                                    </div>
                                    <!-- <div>
                                        <v-btn icon color="success">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>
                                    </div> -->
                                </div>
                            </v-alert>
                        </base-card>
                    </v-col>




                    <v-col    v-if="help_onboarding.professional_notcompleted"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-alert
                                class="text-center"
                                colored-border
                                :color="'red'"
                            >
                                <div class="card-title">
                                    Next Step: Complete your Professional Profile
                                </div>
                                <!-- <p class="text--disabled">11/05/2020</p> -->
                                <p></p>
                                <!-- <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/profileedit"                         
                                >                                    
                                    Click here to complete your Profile                                    
                                </v-btn>  -->
                                <div class="d-flex- justify-space-between text-center">
                                    <div>
                                        Let us know if you are a Therapist or you own a Span Business.
                                        <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/professionalsetup"     
                                    class="mt-3"                    
                                >                                    
                                    Click here to finish setting up your Professional Profile                                    
                                </v-btn> 
                                        <!-- <v-btn icon color="warning">
                                            <v-icon
                                                >mdi-star-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="danger"
                                            @click="deleteNote(note)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn> -->
                                    </div>
                                    <!-- <div>
                                        <v-btn icon color="success">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>
                                    </div> -->
                                </div>
                            </v-alert>
                        </base-card>
                    </v-col>




                    <v-col    v-if="help_onboarding.therapist_startacceptingbookings"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12 text-center">
                                        <div class="card-title mb-0">
                                            Congrats! Your Therapist Profile is Complete!
                                        </div>
                                        <div class="my-3">
                                            You can now start accepting bookings... but first, learn how all this works!
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="6"
                                        class="text-18-left text-center"
                                    >
                                        <!-- <v-icon class="primary--text mb-2"
                                            >mdi-microphone-outline</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Get Noticed</p>
                                        <p class="text--disabled">
                                            Tap into the power of social media by sharing
                                            your Gig, and
                                            <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact.
                                        </p> -->
                                        <v-btn class="" color="success"
                                            >Start Accepting Bookings</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="6"
                                        class="text-18-left text-center"
                                    >
                                        <!-- <v-icon class="info--text mb-2"
                                            >mdi-book-open-variant</v-icon
                                        >
                                        <p class="heading-label ma-0 ">
                                            GET MORE SKILLS & EXPOSURE
                                        </p>
                                        <p class="text--disabled">
                                            Hone your skills and expand your knowledge with
                                            online courses. You’ll be able to offer more .
                                        </p> -->
                                        <v-btn class="" outlined color="primary"
                                            >How does it work?</v-btn
                                        >
                                    </v-col>                                    
                                </v-row>
                            </v-card-text>
                        </base-card>
                    </v-col>






                    <v-col    v-if="help_onboarding.therapist_earnmoreclients"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="card-title mb-0">
                                            Earn more Clients!
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="primary--text mb-2"
                                            >mdi-check-decagram</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Get the Verified Badge</p>
                                        <p class="text--disabled">
                                            Increase your credibility by showing the 'Verified' badge to your clients
                                            <!-- <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact. -->
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >Get the Badge!</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="info--text mb-2"
                                            >mdi-book-open-variant</v-icon
                                        >
                                        <p class="heading-label ma-0 ">
                                            Add Special Skills
                                        </p>
                                        <p class="text--disabled">
                                            Make your profile stand out by listing more details about your skills and services!
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >ADD MORE SKILLS</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="warning--text mb-2"
                                            >mdi-trophy</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Promote Your Profile</p>
                                        <p class="text--disabled">
                                            Make your profile appear first in search results, and increase your bookings.
                                            <!-- <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact. -->
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >PROMOTE YOURSELF</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </base-card>
                    </v-col>




                    <v-col    v-if="help_onboarding.business_startacceptingbookings"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12 text-center">
                                        <div class="card-title mb-0">
                                            Congrats! Your Business Profile is Complete!
                                        </div>
                                        <div class="my-3">
                                            You can now start accepting bookings... but first, learn how all this works!
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="6"
                                        class="text-18-left text-center"
                                    >
                                        <!-- <v-icon class="primary--text mb-2"
                                            >mdi-microphone-outline</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Get Noticed</p>
                                        <p class="text--disabled">
                                            Tap into the power of social media by sharing
                                            your Gig, and
                                            <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact.
                                        </p> -->
                                        <v-btn class="" color="success"
                                            >Start Accepting Bookings</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="6"
                                        class="text-18-left text-center"
                                    >
                                        <!-- <v-icon class="info--text mb-2"
                                            >mdi-book-open-variant</v-icon
                                        >
                                        <p class="heading-label ma-0 ">
                                            GET MORE SKILLS & EXPOSURE
                                        </p>
                                        <p class="text--disabled">
                                            Hone your skills and expand your knowledge with
                                            online courses. You’ll be able to offer more .
                                        </p> -->
                                        <v-btn class="" outlined color="primary"
                                            >How does it work?</v-btn
                                        >
                                    </v-col>                                    
                                </v-row>
                            </v-card-text>
                        </base-card>
                    </v-col>








                    <v-col    v-if="help_onboarding.business_earnmoreclients"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="card-title mb-0">
                                            Earn more Clients!
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="primary--text mb-2"
                                            >mdi-check-decagram</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Get the Verified Badge</p>
                                        <p class="text--disabled">
                                            Increase your credibility by showing the 'Verified' badge to your clients
                                            <!-- <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact. -->
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >Get the Badge!</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="info--text mb-2"
                                            >mdi-book-open-variant</v-icon
                                        >
                                        <p class="heading-label ma-0 ">
                                            Add Special Services
                                        </p>
                                        <p class="text--disabled">
                                            Make your business stand out by listing more details about your services!
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >ADD SPECIAL SERVICES</v-btn
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        class="text-18-left text-center"
                                    >
                                        <v-icon class="warning--text mb-2"
                                            >mdi-trophy</v-icon
                                        >
                                        <p class="heading-label ma-0 ">Promote Your Business</p>
                                        <p class="text--disabled">
                                            Make your business appear first in search results, and increase your bookings.
                                            <!-- <a class="mr-1" href="#">get expert help</a>to
                                            grow your impact. -->
                                        </p>
                                        <v-btn class="" outlined color="primary"
                                            >PROMOTE YOUR BUSINESS</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </base-card>
                    </v-col>



                    

                    <v-col    v-if="help_onboarding.business_findtherapists"                    
                        cols="12"
                        md="12"
                        sm="12"
                        lg="12"
                    >
                        <base-card>
                            <v-alert
                                class="text-center"
                                colored-border
                                :color="'red'"
                            >
                                <div class="card-title">
                                    Find more Therapists for your Business
                                </div>
                                <!-- <p class="text--disabled">11/05/2020</p> -->
                                <p></p>
                                <!-- <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/profileedit"                         
                                >                                    
                                    Click here to complete your Profile                                    
                                </v-btn>  -->
                                <div class="d-flex- justify-space-between text-center">
                                    <div>
                                        Access our curated list of qualified Therapists, and select the ones you like to expand your team!
                                        <v-btn                                    
                                    color="primary"     
                                    block
                                    rounded      
                                    to="/mng/professionalsetup"     
                                    class="mt-3"                    
                                >                                    
                                    Click here to access list of Therapists
                                </v-btn> 
                                        <!-- <v-btn icon color="warning">
                                            <v-icon
                                                >mdi-star-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="danger"
                                            @click="deleteNote(note)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn> -->
                                    </div>
                                    <!-- <div>
                                        <v-btn icon color="success">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>
                                    </div> -->
                                </div>
                            </v-alert>
                        </base-card>
                    </v-col>





                    <v-col                        
                        cols="12"
                        md="12"
                        sm="6"
                        lg="6"
                    >
                        <base-card>
                            <v-alert
                                border="top"
                                colored-border
                                color="blue"                                
                                class="createbooking-bg hoverable"
                                @click="$router.push('/mng/searchbooking')"
                            >
                                <div class="card-title">
                                    Book a Session
                                </div>
                                <!-- <img src="@/assets/images/Booking3_edited.jpg" /> -->
                                <p class="text--disabled" >&nbsp;</p>
                                <!-- <p> test test test  test test test  test test test  test test test  test test test  test test test  test test test  test test test </p>   -->
                                <div class="d-flex justify-space-between">
                                    <div>
                                        <v-btn class="text-capitalize solid-btn" outlined color="primary">
                                            <v-icon left>
                                                mdi-calendar
                                            </v-icon>
                                            <v-icon left>
                                                mdi-repeat
                                            </v-icon>
                                            Repeat Previous Session
                                        </v-btn>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>  
                            </v-alert>
                        </base-card>
                    </v-col>
                    <v-col                        
                        cols="12"
                        md="12"
                        sm="6"
                        lg="6"
                    >
                        <base-card>
                            <v-alert
                                border="top"
                                colored-border
                                color="success lighten-4"  
                                class="viewcalendar-bg hoverable"       
                                @click="redirectToCalendar"                           
                            >
                                <div class="card-title">
                                    My Calendar
                                </div>
                                <p class="text--disabled">&nbsp;</p>
                                <p>&nbsp;</p>  
                                <div class="d-flex justify-space-between">
                                    <div>
                                        
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>  
                            </v-alert>
                        </base-card>
                    </v-col>
                    <v-col
                        v-for="(note, index) in notes"
                        :key="index"
                        cols="12"
                        md="12"
                        sm="6"
                        lg="6"
                    >
                        <base-card>
                            <v-alert
                                border="top"
                                colored-border
                                :color="note.color"
                                class="hoverable"
                            >
                            <v-avatar size="90" class="float-right">
                                    <v-img src="@/assets/images/faces/1.jpg" />
                                </v-avatar>
                                <div class="card-title">
                                    {{ note.title }}
                                </div>
                                <p class="text--disabled">{{ note.time }}</p>
                                <p>{{ note.text }}</p>                                
                                <div class="d-flex justify-space-between">
                                    <!-- <div>
                                        <v-btn icon color="warning">
                                            <v-icon
                                                >mdi-star-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="danger"
                                            @click="deleteNote(note)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div> -->
                                    <!-- <div>
                                        <v-btn icon color="danger">
                                            <v-icon>mdi-heart</v-icon>
                                        </v-btn>
                                    </div> -->
                                    <div>
                                        <v-btn icon color="danger">
                                            <v-icon>mdi-heart-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-alert>
                        </base-card>
                    </v-col>
                </v-row>   
            </v-col>
        </v-row>
        <v-tour name="myTour" :steps="getTourSteps" :callbacks="myTourCallbacks" :options="tourOptions"></v-tour>   
        <v-tour name="myTour_part2" :steps="part2TourSteps" :callbacks="myTourCallbacks_part2"  :options="tourOptions"></v-tour>
    </v-container>
</template>

<script>
import { api } from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersList from "@/components/core/AdvancedGridFiltersList";
import GoogleMap from "@/components/core/GoogleMap";
import axios from 'axios';

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Home'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersList ,
        GoogleMap      
    },
    data() {
        return {
            help_onboarding: {
                normal_completeprofile: false,
                normal_bookyourfirstsession: false,
                professional_notcompleted: false,
                therapist_startacceptingbookings: false,
                therapist_earnmoreclients: false,
                business_startacceptingbookings: false,
                business_earnmoreclients: false,
                business_findtherapists: false,
            },

            myTourCallbacks: {
                // onStart: this.tourCallbackOnStart,
                // onPreviousStep: this.tourCallbackOnPreviousStep,
                // onNextStep: this.tourCallbackOnNextStep,
                // onSkip: this.tourCallbackOnSkip,
                onFinish: this.myTourCallbackOnFinish,
                // onStop: this.tourCallbackOnStop,
            },

            myTourCallbacks_part2: {
                onFinish: this.myTourCallbackOnFinish_part2
            },

            part2TourSteps: [
                {
                    target: '#editprofiletourbutton',
                    content: 'Click this button to go to the Profile Edit Screen'
                }
            ],

            tourOptions: {
                labels: {
                    buttonStop: 'Next'
                }
            },

            

            prefectures: [],
            cities: [],
            selectedCities: [],
            selectedPrefectures: [],
            nearbyCities: [],
            professionalType: null,
            occupations: ['Cosmetology','Physiotherapy','Massage', 'Reflexology'],
            operationYears: [...Array(40).keys()].map(i=>2023-i),
            contacts: [{}],
            notes: [],






            totalItemCount: 0,
            quicksearch: '',
            pagingOptions: {},
            selected: [],
            baseData: [],
            select_field: null,
            select_operator: null,
            filtercriteria: null,
                            
            filtersVisible: false,
            headers: [
                {text: 'Name', align: 'start', value: 'fullname',  sortable: false},
                {text: 'Email', align: 'start', value: 'username'},                
                {text: 'Role', value: 'role'},
                // {text: 'Products', value: 'fat'},
                // {text: 'Wallet Balance', value: 'carbs'},
                {text: 'Status', value: 'status',  sortable: false},
                {text: 'Create Date', value: 'aud_create_date'},                
                {text: 'Action', value: 'action'}
            ]
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).format("DD/MM/YYYY");
        }
    },
    watch: {
      pagingOptions: {
        handler () {
          this.fetchGridData();
        },
        deep: true,
      },
      selectedPrefectures() {
        console.log(JSON.stringify(this.selectedPrefectures));
      },
      async selectedCities() {
        //const citiesToSend = this.selectedCities;
        const rsp = await api.hud.nearbycities(this.selectedCities);
        this.nearbyCities.splice(0);
        this.nearbyCities.push(...rsp.data);
      }
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        // gridData() {
        //     const rspData = this.baseData;
        //     return this.formatListData(rspData);
        // },    
        ...mapGetters(["getTourSteps"]),    
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
        shownCities() {
            let self = this;
            let prefs = this.selectedPrefectures.map(i=>{ return this.prefectures[i] }) || [];
            return this.cities.filter(c=> {
                if(prefs && prefs.length > 0) {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.selectedCities.includes(c.city) )
                        &&  prefs.includes(c.admin_name)                    
                        ;
                    }
                    else {
                        return !self.selectedCities.includes(c.city) &&  prefs.includes(c.admin_name)   ;
                    } 
                }
                else {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.selectedCities.includes(c.city) )                    
                        ;
                    }
                    else {
                        return !self.selectedCities.includes(c.city);
                    } 
                }
                               
            }).slice(0, 20);
        },
        pois() {
            // return [
            //     {position: { lat: 38.03395168814914, lng: 23.74479997873596 }, fillColor: "black" },
            //     {position: { lat: 38.096211703562744, lng: 23.82383093781674 }, fillColor: "black" },
            // ];
            return this.cities.filter(c=> {
                return this.selectedCities.includes(c.city)
            }).map(c=> { return {position: {lat: parseFloat(c.lat), lng: parseFloat(c.lng)}}});
        }
    },
    methods: {
        ...mapMutations(['showOnboardingDialog','hideOnboardingDialog','setTourSteps', 'setRefSystemServices', 'setRefSystemServiceGroups']),
        ...mapActions(["closeVerticalSaasSidebarDrawer", "openVerticalSaasSidebarDrawer"]),
        // formatListData(rspData) {
        //     const d = rspData.map(d => { 
        //         return {
        //             ...d, 
        //             fullname: d.firstName + ' ' + d.lastName,
        //             status: ( (d.disabled) ? "Disabled" :
        //                 (d.invitation_code) ? "Invited" :
        //                 (d.activation_date === "" || d.activation_date == null) ? "Awaiting Activation" : "Active"                         
        //             )
        //         } })
        //     return d;
        // },
        redirectToCalendar() {
            this.$router.push('/app/extraUiKits/calendars');
        },
        showOnboardingDialog2() {
            this.$tours['myTour'].start();
        },
        selectCity(cityToSelect) {
            this.selectedCities.push(cityToSelect);
            this.quicksearch = null;
        },
        deselectCity(cityToDeselect) {
            this.selectedCities.splice(this.selectedCities.indexOf(cityToDeselect),1);
        },
        async fetchGridData() {            
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.filtercriteria && this.filtercriteria.qs) {                
                qstr += this.filtercriteria.qs;
            }      
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 2){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            }      
            // //alert(querystr);
            // const rsp = await api.users.getList(qstr);
            // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
            // this.totalItemCount = rsp.data.total;
        },
        toggleFilterView() {
            this.filtersVisible = ! this.filtersVisible
        },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            //this.fetchGridData();       
        },
        myTourCallbackOnFinish(/*currentStepIndex*/) {
            //alert("tour finish " /*+ currentStepIndex*/);
            let self = this;
            document.getElementById("userdrawerclicktarget").click();
            // this.setTourSteps([
            //     {
            //         target: '#editprofiletourbutton',
            //         content: 'Click this button to go to the Profile Edit Screen'
            //     }
            // ]);
            //self.$tours['myTour'].start();
            //setTimeout(()=>{self.$tours['myTour'].start();}, 1500);
            self.$tours['myTour_part2'].start();
            //setTimeout(()=>{self.$tours['myTour_part2'].start();}, 1500);
            //document.getElementById("editprofiletourbutton").click();
        },    
        myTourCallbackOnFinish_part2(/*currentStepIndex*/) {            
            document.getElementById("editprofiletourbutton").click();
        },     
    },
    async created() {
        axios.get('/api/notes').then((response) => {
            this.notes = response.data
        })
        //this.fetchGridData();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {
        //let ff = this.filterfields.find(i=>i.value === "brand");      
        const rsp = await api.hud.myhomepageinfo();
        //console.log(JSON.stringify(rsp.data, null, 2));  
        if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
            this.openVerticalSaasSidebarDrawer();
        }
        this.prefectures.push(...rsp.data.prefectures);
        this.cities.push(...rsp.data.areas);

        // this.setRefSystemServices(rsp.data.service_types);
        // this.setRefSystemServiceGroups(rsp.data.service_type_groups);

        //this.showOnboardingDialog();
        // if(ff) {
        //     const rsp = await api.brand.getAll();            
        //     ff.listItems = rsp.data.map(i=> {return {value: i.code, text: i.title}});
        // }
    }
}
</script>
<style lang="scss" scoped>

.solid-btn.v-btn--outlined {
    //border: thin solid #CCCCCC;
    background-color: #FFFFFF;
  }

.hoverable {
    cursor: pointer;
}

.hoverable:hover {    
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.createbooking-bg {
    background-image: url('../../../assets/images/Booking3_edited_b.jpg');
    background-position: right; 
    height: 170px;
    // height: 100vh;
    // align-items: center;
    // place-content: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // display: flex;
}

.viewcalendar-bg {
    background-image: url('../../../assets/images/Calendar_edited.jpg');
    background-position: right; 
    height: 170px;
    // height: 100vh;
    // align-items: center;
    // place-content: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // display: flex;
}

::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}


</style>
